import {AnalyticsStateMachine} from '../../analyticsStateMachines/AnalyticsStateMachine';
import {EventDispatcher} from '../../core/EventDispatcher';
import {AnalyticsConfig} from '../../types/AnalyticsConfig';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DownloadSpeedInfo} from '../../types/DownloadSpeedInfo';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {Sample} from '../../types/Sample';
import {SegmentInfo} from '../../types/SegmentInfo';
import {StateMachineCallbacks} from '../../types/StateMachineCallbacks';
import {VideoStartFailedEvent} from '../../types/VideoStartFailedEvent';
import {logger} from '../../utils/Logger';
import {ANALYTICS_QUALITY_CHANGE_COUNT_THRESHOLD} from '../../utils/Settings';
import {getCurrentTimestamp} from '../../utils/Utils';

export abstract class InternalAdapter {
  // tslint:disable:no-unused-expression
  public stateMachineCallbacks: StateMachineCallbacks = {
    setup: (time: number, state: string, event: string) => void {},
    startup: (time: number, state: string) => void {},
    playing: (time: number, state: string, event: string) => void {},
    heartbeat: (time: number, state: string, payload: Pick<Sample, 'played'> | Pick<Sample, 'buffered'>) => void {},
    qualitychange: (time: number, state: string) => void {},
    qualitychange_pause: (time: number, state: string) => void {},
    qualitychange_rebuffering: (time: number, state: string) => void {},
    videoChange: (event: string) => void {},
    audioChange: (event: any) => void {},
    audiotrack_changing: () => void {},
    pause: (time: number, state: string, event: string) => void {},
    paused_seeking: (time: number, state: string, event: string) => void {},
    end_play_seeking: (time: number, state: string, event: string) => void {},
    rebuffering: (time: number, state: string, event: string) => void {},
    error: (event: any) => void {},
    end: (time: number, state: string, event: string) => void {},
    unload: (time: number, state: string, event: string) => void {},
    ad: (time: number, state: string, event: string) => void {},
    mute: () => void {},
    unMute: () => void {},
    subtitle_changing: () => void {},
    setVideoTimeEndFromEvent: (event: any) => void {},
    setVideoTimeStartFromEvent: (event: any) => void {},
    videoStartFailed: (event: VideoStartFailedEvent, sendRequest: boolean) => void {},
    source_changing: (time: number, state: string, event: any) => void {},
    ready: (time: number, state: string, event: any) => void {},
    startCasting: (time: number, event: any) => void {},
    manualSourceChange: (event: {config: AnalyticsConfig}) => void {},
    initialSourceChange: (event: {config: AnalyticsConfig}) => void {},
    muting_ready: (time: number, state: string, event: any) => void {},
  };
  // tslint:enable:no-unused-expression
  protected stateMachine!: AnalyticsStateMachine;

  protected opts: AnalyticsStateMachineOptions;
  protected _onLicenseKeyReceived: EventDispatcher<{licenseKey: string}> = new EventDispatcher();
  protected _onLicenseCallFailed: EventDispatcher<{}> = new EventDispatcher();

  protected drmPerformanceInfo: DrmPerformanceInfo | undefined = undefined;
  protected previousVideoBitrate: number = 0;
  protected previousAudioBitrate: number = 0;
  protected qualityChangeCount: number = 0;

  get onLicenseKeyReceived() {
    return this._onLicenseKeyReceived;
  }

  get onLicenseCallFailed() {
    return this._onLicenseCallFailed;
  }

  get downloadSpeedInfo(): DownloadSpeedInfo {
    return {
      segmentsDownloadCount: 0,
      segmentsDownloadSize: 0,
      segmentsDownloadTime: 0,
      avgDownloadSpeed: 0,
      minDownloadSpeed: 0,
      maxDownloadSpeed: 0,
      avgTimeToFirstByte: 0,
    };
  }

  get segments(): SegmentInfo[] {
    return [];
  }

  constructor(opts?: AnalyticsStateMachineOptions) {
    if (!opts) {
      opts = {
        starttime: undefined,
      };
    }
    if (!opts.starttime) {
      opts.starttime = getCurrentTimestamp();
    }

    this.opts = opts;
  }

  public eventCallback = (eventType: string, eventObject: any) => {
    eventObject = eventObject || {};
    if (!this.stateMachine) {
      logger.log("Bitmovin Analytics: StateMachine isn't ready yet");
    } else {
      this.stateMachine.callEvent(eventType, eventObject, getCurrentTimestamp());
    }
  };

  public getCommonPlaybackInfo() {
    return {
      screenHeight: screen.height,
      screenWidth: screen.width,
    };
  }

  public clearValues(): void {
    // noop by default
  }

  public clearSegments(): void {
    // noop by default
  }

  public increaseQualityChangeCount(): void {
    this.qualityChangeCount++;
  }

  public resetQualityChangeCount(): void {
    this.qualityChangeCount = 0;
  }

  public isQualityChangeEventEnabled(): boolean {
    return this.qualityChangeCount <= ANALYTICS_QUALITY_CHANGE_COUNT_THRESHOLD;
  }

  protected shouldAllowVideoQualityChange(newBitrate: number | undefined): boolean {
    return (
      newBitrate != null &&
      !isNaN(newBitrate) &&
      this.previousVideoBitrate !== newBitrate &&
      this.isQualityChangeEventEnabled()
    );
  }

  protected setPreviousVideoBitrate(newBitrate: number): void {
    this.previousVideoBitrate = newBitrate;
  }

  protected shouldAllowAudioQualityChange(newBitrate: number | undefined): boolean {
    return (
      newBitrate != null &&
      !isNaN(newBitrate) &&
      this.previousAudioBitrate !== newBitrate &&
      this.isQualityChangeEventEnabled()
    );
  }

  protected setPreviousAudioBitrate(newBitrate: number): void {
    this.previousAudioBitrate = newBitrate;
  }
}
