import {ErrorCode} from '../enums/ErrorCode';
import {Event} from '../enums/Event';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import ErrorEventObject from '../types/ErrorEventObject';
import {StateMachineCallbacks} from '../types/StateMachineCallbacks';
import * as Settings from '../utils/Settings';
import * as Utils from '../utils/Utils';

const REBUFFERING_HEARTBEAT_INTERVALS = [3000, 5000, 10000, 30000, 59700];

export abstract class AnalyticsStateMachine {
  protected stateMachine: StateMachine.StateMachine & {[key: string]: any};
  protected onEnterStateTimestamp: number = 0;

  private rebufferingHeartbeatIntervalHandle?: number;
  private currentRebufferingIntervalIndex: number = 0;
  private rebufferingTimeoutHandle?: number;

  constructor(protected stateMachineCallbacks: StateMachineCallbacks, opts: AnalyticsStateMachineOptions) {
    this.stateMachine = this.createStateMachine(opts);
  }

  public abstract createStateMachine(opts: AnalyticsStateMachineOptions): StateMachine.StateMachine;
  public abstract callEvent(eventType: string, eventObject: any, timestamp: number): void;
  public abstract sourceChange(config: any, timestamp: number, currentTime?: number): void;

  protected startRebufferingHeartbeatInterval(reset: boolean = true) {
    this.resetRebufferingHelpers(reset);

    this.startRebufferingTimeoutHandle();

    this.rebufferingHeartbeatIntervalHandle = window.setInterval(() => {
      if (this.stateMachine.current.toLowerCase() !== 'rebuffering') {
        this.resetRebufferingHelpers();
        return;
      }
      const timestamp = new Date().getTime();
      const stateDuration = timestamp - this.onEnterStateTimestamp;
      this.stateMachineCallbacks.heartbeat(stateDuration, this.stateMachine.current.toLowerCase(), {
        buffered: stateDuration,
      });
      this.onEnterStateTimestamp = timestamp;
      this.currentRebufferingIntervalIndex = Math.min(
        this.currentRebufferingIntervalIndex + 1,
        REBUFFERING_HEARTBEAT_INTERVALS.length - 1
      );
      this.startRebufferingHeartbeatInterval(false);
    }, REBUFFERING_HEARTBEAT_INTERVALS[this.currentRebufferingIntervalIndex]);
  }

  protected resetRebufferingHelpers(reset: boolean = true) {
    if (reset) {
      this.currentRebufferingIntervalIndex = 0;
    }
    this.clearRebufferingHeartbeatHandle();
    this.clearRebufferingTimeoutHandle(reset);
  }

  protected clearRebufferingHeartbeatHandle() {
    if (this.rebufferingHeartbeatIntervalHandle != null) {
      window.clearInterval(this.rebufferingHeartbeatIntervalHandle);
      this.rebufferingHeartbeatIntervalHandle = undefined;
    }
  }

  protected startRebufferingTimeoutHandle() {
    if (this.currentRebufferingIntervalIndex > 0) {
      return;
    }
    this.rebufferingTimeoutHandle = window.setTimeout(() => {
      this.callEvent(Event.ERROR, new ErrorEventObject(ErrorCode.BufferingTimeoutReached), Utils.getCurrentTimestamp());
    }, Settings.ANALYTICS_REBUFFER_TIMEOUT);
  }

  protected clearRebufferingTimeoutHandle(reset: boolean) {
    if (reset && this.rebufferingTimeoutHandle != null) {
      window.clearTimeout(this.rebufferingTimeoutHandle);
      this.rebufferingTimeoutHandle = undefined;
    }
  }
}
