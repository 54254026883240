import {AdAnalyticsSample} from '../types/AdAnalyticsSample';
import {AdBreakSample} from '../types/AdBreakSample';
import {AdSample} from '../types/AdSample';
import {Sample} from '../types/Sample';
import {Backend} from './Backend';

export class DisableBackend implements Backend {
  private _enabled: boolean = true;
  private startupInformation: any;
  private startupInformationSent: boolean = false;

  constructor(private innerBackend: Backend) {}

  public get enabled() {
    return this._enabled;
  }

  public set enabled(value: boolean) {
    this._enabled = value;
  }

  public sendRequest(sample: Sample) {
    if (!this.decorateSampleIfEnabled(sample)) {
      return;
    }
    this.innerBackend.sendRequest(sample);
  }

  public sendUnloadRequest(sample: Sample) {
    if (!this._enabled) {
      return;
    }
    this.innerBackend.sendUnloadRequest(sample);
  }
  public sendRequestSynchronous(sample: Sample) {
    if (!this.decorateSampleIfEnabled(sample)) {
      return;
    }
    this.innerBackend.sendRequestSynchronous(sample);
  }
  public sendAdRequest(sample: AdSample & AdBreakSample & AdAnalyticsSample) {
    if (!this._enabled) {
      return;
    }
    this.innerBackend.sendAdRequest(sample);
  }

  private decorateSampleIfEnabled(sample: Sample): boolean {
    if (!this.startupInformationSent && sample.playerStartupTime && sample.playerStartupTime > 0) {
      this.startupInformation = {
        playerStartupTime: sample.playerStartupTime,
        pageLoadTime: sample.pageLoadTime,
      };
      if (this._enabled === true) {
        this.startupInformationSent = true;
        return true;
      }
    }
    if (!this._enabled) {
      return false;
    }
    if (
      !this.startupInformationSent &&
      this.startupInformation &&
      sample.videoStartupTime &&
      sample.videoStartupTime > 0
    ) {
      this.copyPropertiesToSample(sample, this.startupInformation);
      sample.startupTime = (sample.playerStartupTime || 0) + sample.videoStartupTime;
      this.startupInformationSent = true;
    }
    return true;
  }

  private copyPropertiesToSample(sample: Sample, startupInfo: any) {
    Object.getOwnPropertyNames(startupInfo).forEach(
      (propertyName) => (sample[propertyName] = startupInfo[propertyName])
    );
  }
}
