import {ErrorCode} from '../enums/ErrorCode';

class ErrorEventObject {
  public readonly code: number;
  public readonly message?: string;

  constructor(code: number) {
    this.code = code;
    this.message = this.getMessageFromCode(code);
  }

  private getMessageFromCode(code: number): string | undefined {
    switch (code) {
      case ErrorCode.BufferingTimeoutReached:
        return 'ANALYTICS_BUFFERING_TIMEOUT_REACHED';
      default:
        return undefined;
    }
  }
}

export default ErrorEventObject;
